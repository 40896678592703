import axios from "axios";

const state = {
  transport: [],
  currentStop: [],
  geometryRoutes: [],
  currentRouteSchedule: [],
  scheduleStationRoutes: [],
  listRoutesPassingBusStop: [],

  bus_routes: [],
  routesThroughBaseStation: [],
  routesPrediction: [],
  currentBusRouteGeometry: [],
  routesThroughSelectedStation: [],
}

const getters = {
  BUS_STATION: state => {
    return state.transport.stops;
  },
  GET_STOP: state => {
    return state.currentStop;
  },
  BUS_ROUTES: state => {
    return state.bus_routes;
  },
  ROUTES_THROUGH_BASE_STATION: state => {
    return state.routesThroughBaseStation;
  },
  ROUTES_PREDICTION: state => {
    return state.routesPrediction;
  },
  CURRENT_BUS_ROUTE_GEOMETRY: state => {
    return state.currentBusRouteGeometry;
  },
  ROUTES_THROUGH_SELECTED_STATION: state => {
    return state.routesThroughSelectedStation;
  },
  GET_ROUTE_SCHEDULE: state => {
    return state.currentRouteSchedule;
  },





  GEOMETRY_ROUTES: state => {
    return state.geometryRoutes.map(el => {
      return [el.Lat, el.Lon];
    });
  },

  SCHEDULE_STATION_ROUTES: state => {
    return state.scheduleStationRoutes;
  },
  LIST_ROUTES_PASSING_BUS_STOP: state => {
    return state.listRoutesPassingBusStop;
  },
  LIST_ROUTES_DIRECTION: state => {
    let track = state.listRoutesWithDirectionDirect.concat(state.listRoutesWithDirectionBackward);
    return track.map(el => {
      return [el.Lat, el.Lon];
    })
  }
}

const actions = {
  GET_STATIONS: async ({ commit }, payload) => {
    let { data } = await axios.get(`https://smart-fe26.ru/transport/rest/bus/env/station`);
    const currentStop = data.filter(item => item.id == payload);
    commit("SET_STATIONS", data);
    commit("SET_STOP", ...currentStop);
  },

  GET_ROUTES_THROUGH_BASE_STATION: async  ({ commit }, payload) => {
    let { data } = await axios.get(`https://smart-fe26.ru/transport/rest/bus/forecast/${payload}`);
    commit("SET_ROUTES_THROUGH_BASE_STATION", data);
  },

  GET_ROUTES_PREDICTION: async ({ commit }, payload) => {
    let { data } = await axios.get(`https://smart-fe26.ru/transport/rest/bus/forecast/${payload}`);
    commit("SET_ROUTES_PREDICTION", data);
  },

  GET_BUS_ROUTE_GEOMETRY: async  ({ commit }, payload) => {
    let { data } = await axios.get(`https://smart-fe26.ru/transport/rest/bus/env/route/${payload}?geojs=false`);
    commit("SET_BUS_ROUTE_GEOMETRY", data);
  },

  GET_ROUTES_THROUGH_SELECTED_STATION: async  ({ commit }, payload) => {
    let { data } = await axios.get(`https://smart-fe26.ru/transport/rest/bus/forecast/${payload}`);
    commit("SET_ROUTES_THROUGH_SELECTED_STATION", data);
  },

  GET_ROUTE_SCHEDULE_FROM_SERVER: async ({ commit }, payload) => {
    let { data } = await axios.get(`https://smart-fe26.ru/transport/rest/bus/schedule?rid=${payload.params.routeId}`);
    commit("SET_ROUTE_SCHEDULE", data);
  },

  // GET_STOP: async ({ commit }, payload) => {
  //   let { data } = await axios.get(`${process.env.VUE_APP_TRANSPORT_URL}/stop/${payload}`);
  //   commit("SET_STOP", data.data);
  // },

  // GET_TRANSPORT: async ({ commit }) => {
  //   let { data } = await axios.get(`${process.env.VUE_APP_TRANSPORT_URL}/stops/${process.env.VUE_APP_TEMPORY_CAP_REGION_ID}`);
  //   commit("SET_TRANSPORT", data.data);
  // },

  // GET_GEOMETRY_ROUTES: async ({ commit }, payload) => {
  //   let { data } = await axios.get(`${process.env.VUE_APP_TRANSPORT_URL}/track_dir/${payload.routeId}/${payload.stopId}`)
  //   commit("SET_GEOMETRY_ROUTES", data.data);
  // },

  // GET_SCHEDULE_STATION_ROUTES: async ({ commit }, payload) => {
  //   let { data } = await axios.get(`${process.env.VUE_APP_TRANSPORT_URL}/schedule_dir/${payload.routeId}/${payload.stopId}`);
  //   commit("SET_SCHEDULE_STATION", data.data);
  // },

  // GET_LIST_ROUTES_PASSING_BUS_STOP: async ({commit}, busStationId) => {
  //   let { data } = await axios.get(`${process.env.VUE_APP_TRANSPORT_URL}/routes/${busStationId}`);
  //   commit("SET_LIST_ROUTES_PASSING_BUS_STOP", data.data)
  // },

  // GET_LIST_ROUTES_WITH_DIRECTION_DIRECT: async ({commit}, payload) => {
  //   let { data } = await axios.get(`${process.env.VUE_APP_TRANSPORT_URL}/track/${payload.routeId}/${payload.direction}`);
  //   commit("SET_LIST_ROUTES_WITH_DIRECTION_DIRECT", data.data);
  // },

  // GET_LIST_ROUTES_WITH_DIRECTION_BACKWARD: async ({commit}, payload) => {
  //   let { data } = await axios.get(`${process.env.VUE_APP_TRANSPORT_URL}/track/${payload.routeId}/${payload.direction}`);
  //   commit("SET_LIST_ROUTES_WITH_DIRECTION_BACKWARD", data.data);
  // }

}

const mutations = {
  SET_STATIONS: (state, payload) => {
    state.transport.stops = payload;
  },

  SET_STOP: (state, payload) => {
    state.currentStop = payload;
  },

  SET_BUS_ROUTES: (state, payload) => {
    state.bus_routes = payload;
  },

  SET_ROUTES_THROUGH_BASE_STATION: (state, payload) => {
    state.routesThroughBaseStation = payload;
  },

  SET_ROUTES_PREDICTION: (state, payload) => {
    state.routesPrediction = payload;
  },

  SET_BUS_ROUTE_GEOMETRY: (state, payload) => {
    state.currentBusRouteGeometry = payload;
  },

  SET_ROUTES_THROUGH_SELECTED_STATION: (state, payload) => {
    state.routesThroughSelectedStation = payload;
  },

  SET_ROUTE_SCHEDULE: (state, payload) => {
    state.currentRouteSchedule = payload;
  },







  // SET_TRANSPORT: (state, payload) => {
  //   state.transport = payload;
  // },
  //
  // SET_GEOMETRY_ROUTES: (state, payload) => {
  //   state.geometryRoutes = payload;
  // },
  //
  // SET_SCHEDULE_STATION: (state, payload) => {
  //   state.scheduleStationRoutes = payload;
  // },
  // SET_LIST_ROUTES_PASSING_BUS_STOP: (state, payload) => {
  //   state.listRoutesPassingBusStop = payload;
  // },
  // SET_LIST_ROUTES_WITH_DIRECTION_DIRECT: (state, payload) => {
  //   state.listRoutesWithDirectionDirect = payload;
  // },
  // SET_LIST_ROUTES_WITH_DIRECTION_BACKWARD: (state, payload) => {
  //   state.listRoutesWithDirectionBackward = payload;
  // }
}

export default {
  state,
  getters,
  actions,
  mutations
}
