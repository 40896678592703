import axios from "axios";

const state = {
  places: [],
  filteredPlaces: [],
  placesCategories: [],
  loader: false,
  notification: ""
};

const getters = {
  GET_PLACES: state => state.places,
  GET_FILTERED_PLACES: state => state.filteredPlaces,
  GET_PLACES_CATEGORIES: state => state.placesCategories,
  LOADER: state =>  state.loader,
  NOTIFICATION: state => state.notification,
};

const actions = {
  GET_PLACES_FROM_SERVER: async ({ commit }) => {
    const { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/places?resolution=small&city_id=8&count=1000&show=additions,address,contacts,qr_code,description,id,images,location,title,work_hours,category`);
    commit("SET_LOADER", true)
  
    setTimeout(() => {
      if (data.result === "ok") {
        commit("SET_PLACES", data.data);
        commit("SET_LOADER", false);
      }
    }, 500);
  },
  GET_FILTERED_PLACES_FROM_SERVER: async ({ commit }, payload) => {
    commit("SET_NOTIFICATION", "");
    commit("SET_LOADER", true);
    
    setTimeout(() => {
      if (payload.places.length < 1) {
        commit("SET_NOTIFICATION", "Объектов пока нет, попробуйте другой запрос");
      }
      commit('SET_FILTERED_PLACES', payload.places);
      commit("SET_LOADER", false);
    }, 500);
  },
  GET_PLACES_CATEGORIES_FROM_SERVER: async ({ commit }) => {
    const { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/categories/places?city_id=8`);
    commit('SET_PLACES_CATEGORIES', data.data);
  },
};

const mutations = {
  SET_PLACES: (state, payload) => {
    state.places = payload;
  },
  SET_FILTERED_PLACES: (state, payload) => {
    state.filteredPlaces = [...payload];
  },
  SET_PLACES_CATEGORIES: (state, payload) => {
    state.placesCategories = payload;
  },
  SET_LOADER: (state, payload) => {
    state.loader = payload;
  },
  SET_NOTIFICATION: (state, payload) => {
    state.notification = payload;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
