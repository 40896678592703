<template>
  <div v-if="busCards.length > 0">
    <div class="bus-card" v-if="busCards.length > 0">
      <div v-for="item in busCards.slice(0, 4)"
           :key="item.route.id"
           class="card"
      >
        <div class="card-left">
          <p class="card-left__bus-number">
            <marquee-text :duration="30" :repeat="1" v-if="item.route.title.length > 6">
              {{ item.route.title }}
            </marquee-text>

            <span v-else>
              {{ item.route.title }}
            </span>
          </p>
        </div>

        <p v-if="item.forecasts.length > 0 && parseInt(item.forecasts[0].seconds) == 0" class="card__bus-arrival">
          <img src="@/assets/img/busCircle.svg"/>
          прибыл
        </p>

        <p v-if="item.forecasts.length > 0 && parseInt(item.forecasts[0].seconds) < 60 && parseInt(item.forecasts[0].seconds) > 0" class="card__bus-arrival">
          <img src="@/assets/img/busCircle.svg"/>
          прибывает
        </p>

        <p v-if="item.forecasts.length > 0 && parseInt(item.forecasts[0].seconds) > 60" class="card__bus-arrival">
          через {{ Math.round(item.forecasts[0].seconds / 60) }} мин
        </p>

        <p v-if="item.forecasts.length > 0 && item.forecasts[0].seconds === null" class="card__bus-arrival">
          данные подгружаются
        </p>
      </div>
    </div>

    <div v-else>
      Данные загружаются...
    </div>
  </div>
</template>

<script>
import MarqueeText from "vue-marquee-text-component";

export default {
  name: "BusCard",

  components: {
    MarqueeText,
  },

  props: {
    busCards: {
      type: [Array, Object],
      default: () => ([])
    }
  }
}
</script>

<style lang="scss" scoped>
.bus-card {
  display: flex;
  flex-wrap: wrap;

  width: 100%;
  max-height: 250px;
}

.card {
  display: flex;
  justify-content: space-between;
  width: 500px;
  height: 100px;
  margin-bottom: 16px;
  padding: 16px;
  border-radius: 12px;
  border-left: 12px solid #0b83ff;
  background: #1E1E1E;

  &--first-card {
    color: red;
  }

  .card-left {
    &__bus-number {
      width: 219px;

      font-size: 40px;
      font-weight: 500;
      text-transform: uppercase;
    }

    &__bus-station {
      font-size: 24px;
      font-weight: normal;
      color: rgba(#fff, 0.7)
    }
  }

  &__bus-arrival {
    display: flex;
    align-items: flex-start;
    font-size: 30px;
    font-weight: 500;

    padding-left: 16px;

    img {
      margin-right: 16px;
    }
  }

  &:nth-of-type(odd) {
    margin-right: 13px;
  }

  //&:nth-of-type(1) {
  //  border-left: 12px solid #ff510b;
  //}
}

.bus-cards {
  display: flex;
  width: 100%;
}
</style>
