const getDefaultState = () => {
  return {
    coord: null,
    city: null,
    address: null,
    zoom: null,
    stopId: null
  };
};

const state = getDefaultState();

const actions = {
  fetchPanel: ({ commit }, payload) => {
    api.getPanel(payload.panelId).then(res => {
      commit("setPanel", res.data[0]);
      commit("setStop", payload.stopId);
    });
  },
};

const getters = {
  coord: state => state.coord,
  zoom: state => state.zoom,
  title: state => state.title,
  stopId: state => state.stopId
};

const mutations = {
  setPanel(state, res) {
    state.coord = res.location;
    state.city = res.city_id;
    state.address = res.address;
    state.zoom = res.zoom ? res.zoom : 10;
    state.title = res.title;
  },
  setStop(state, id) {
    state.stopId = id;
  }
};

export default {
  state,
  actions,
  getters,
  mutations
};
