<template>
  <div id="app">
    <div v-if="loaded">
      <top-bar
          :old-style="true"
          @show-route="showRoute"
      />
      <router-view
          :route="route"
      />
      <bottom-bar />
    </div>

    <div v-else>
      loading...
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import TopBar from "@/components/TopBar";
import BottomBar from "@/components/BottomBar";
import {getCurrentBusesOnRoute} from "@/api/socket";

export default {
  components: { TopBar, BottomBar },

  data() {
    return {
      route: null,
      loaded: false,
    }
  },

  computed: {
    stopId() {
      return this.$route.query.stop_id;
    }
  },

  beforeCreate() {
    this.$router.onReady(() => {
      this.$store.dispatch("fetchPanel", {
        panelId: this.$route.query.panel_id,
        stopId: this.$route.query.stop_id,
      });
    });
  },

  async mounted() {
    await this.awaitStore();

    this.$echo.channel('model-update-channel')
        .listen('.model.updated', (response) => {
          switch (response.entity) {
            case 'panel-banners':
              this.$store.dispatch('GET_BANNERS_FROM_SERVER');
              break;
            case 'stories':
              this.$store.dispatch("GET_STORIES_FROM_SERVER");
              break;
            case 'events':
              this.$store.dispatch('GET_EVENTS_FROM_SERVER');
              break;
            case 'places':
              this.$store.dispatch('GET_PLACES_FROM_SERVER');
              break;
            case 'routes':
              this.$store.dispatch('GET_ROUTES_FROM_SERVER');
              break;
            case 'excursions':
              this.$store.dispatch('GET_EXCURSIONS_FROM_SERVER');
              break;
            default:
              break;
          }
        }).error((error) => console.log(error));

    window.idleTimer = null;
    window.idleWait = process.env.NODE_ENV == "development" ? 1000000 : 45000;

    const listener = () => {
      clearTimeout(window.idleTimer);

      window.idleTimer = setTimeout(() => {
        if (this.$route.name == "StoryScreen") listener();
        else this.goToStartScreen();
      }, window.idleWait);
    };

    listener();
    document.querySelector("body").addEventListener("mousemove", listener); // its realy needed?
    document.querySelector("body").addEventListener("touchstart", listener);
    document.querySelector("body").addEventListener("keydown", listener);

    if (this.$route.query.stop_id) {
      localStorage.setItem('stopId', this.$route.query.stop_id);
    }

    this.GET_STATIONS(localStorage.getItem('stopId'));
    this.GET_ROUTES_THROUGH_BASE_STATION(localStorage.getItem('stopId'));
    this.GET_ROUTES_PREDICTION(localStorage.getItem('stopId'));
  },

  methods: {
    ...mapActions(["GET_STATIONS", "GET_ROUTES_THROUGH_BASE_STATION", "GET_ROUTES_PREDICTION"]),

    goToStartScreen() {
      if (this.$route.path !== "/") this.$router.push({ path: "/" });
    },

    showRoute(item) {
      this.route = item;
    },

    awaitStore() {
      Promise.all([
        this.$store.dispatch("GET_STORIES_FROM_SERVER"),
        this.$store.dispatch("GET_EVENTS_FROM_SERVER"),
        this.$store.dispatch("GET_PLACES_FROM_SERVER"),
        this.$store.dispatch("GET_PLACES_CATEGORIES_FROM_SERVER"),
        this.$store.dispatch('GET_BANNERS_FROM_SERVER'),
        this.$store.dispatch('GET_EXCURSIONS_FROM_SERVER'),
        this.$store.dispatch('GET_EXCURSIONS_CATEGORIES_FROM_SERVER'),
        this.$store.dispatch('GET_ROUTES_FROM_SERVER'),
        this.$store.dispatch('GET_ROUTES_CATEGORIES_FROM_SERVER'),
        this.$store.dispatch('GET_FACTS_FROM_SERVER'),
      ]).finally(() => {
        this.loaded = true;
      });
    },
  },

  watch: {
    stopId: function(newVal) {
      if (newVal !== undefined) {
        localStorage.setItem('stopId', newVal)
      }

      getCurrentBusesOnRoute();
    }
  }
};
</script>

<style lang="scss">
@import "@/scss/main.scss";
.leaflet-bottom {
  display: none;
}
</style>
