import axios from "axios";

const state = {
  excursions: [],
  routes: [],
  filtered: [],
  notification: '',
  loader: false,
  types: [
    {
      type: "all",
      name: "Все",
      id: 1
    },
    {
      type: "route",
      name: "Маршруты",
      id: 2,
      subtypes: []
    },
    {
      type: "excursion",
      name: "Экскурсии",
      id: 3,
      subtypes: []
    }
  ],
};

const getters = {
  GET_EXCURSIONS: state => state.excursions,
  GET_ROUTES: state => state.routes,
  GET_TYPES: state => state.types,
  GET_FILTERED_ROUTES_OR_EXCURSIONS: state => state.filtered,
  GET_ROUTES_AND_EXCURSIONS_NOTIFICATION: state => state.notification,
  GET_ROUTES_AND_EXCURSIONS_LOADER: state =>  state.loader,
};

const mutations = {
  SET_EXCURSIONS: (state, payload) => {
    state.excursions = payload;
  },
  SET_ROUTES: (state, payload) => {
    state.routes = payload;
  },
  SET_ROUTES_CATEGORIES: (state, payload) => {
    state.types[1].subtypes = payload;
  },
  SET_EXCURSIONS_CATEGORIES: (state, payload) => {
    state.types[2].subtypes = payload;
  },
  SET_FILTERED_ROUTES_OR_EXCURSIONS: (state, payload) => {
    state.filtered = payload;
  },
  SET_ROUTES_AND_EXCURSIONS_LOADER: (state, payload) => {
    state.loader = payload;
  },
  SET_ROUTES_AND_EXCURSIONS_NOTIFICATION: (state, payload) => {
    state.notification = payload;
  }
};

const actions = {
  GET_EXCURSIONS_FROM_SERVER: async ({ commit }) => {
    let { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/excursions?sort=avg&city_id=8&count=1000`);
    commit('SET_EXCURSIONS', data.data);
  },
  GET_EXCURSIONS_CATEGORIES_FROM_SERVER: async ({ commit }) => {
    const { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/categories/excursions?city_id=8&not_empty=true`);
    commit('SET_EXCURSIONS_CATEGORIES', data.data);
  },
  GET_ROUTES_FROM_SERVER: async ({ commit }) => {
    let { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/routes?sort=avg&city_id=8&resolution=small&count=1000`);
    commit('SET_ROUTES', data.data);
  },
  GET_ROUTES_CATEGORIES_FROM_SERVER: async ({ commit }) => {
    const { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/categories/routes?city_id=8&not_empty=true`);
    commit('SET_ROUTES_CATEGORIES', data.data);
  },
  GET_FILTERED_ROUTES_OR_EXCURSIONS_FROM_SERVER: async ({ commit }, payload) => {
    commit("SET_ROUTES_AND_EXCURSIONS_NOTIFICATION", "");
    commit("SET_ROUTES_AND_EXCURSIONS_LOADER", true);
    
    setTimeout(() => {
      if (payload.length < 1) {
        commit("SET_ROUTES_AND_EXCURSIONS_NOTIFICATION", "По этой категории пока ничего нет, попробуйте другой запрос");
      }
      commit('SET_FILTERED_ROUTES_OR_EXCURSIONS', payload);
      commit("SET_ROUTES_AND_EXCURSIONS_LOADER", false);
    }, 500);
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
}
