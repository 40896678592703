import axios from "axios";
const state = {
  facts: [],
}

const actions = {
  GET_FACTS_FROM_SERVER: async ({ commit }) => {
    const { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/facts?city_id=8`);
    commit('SET_FACTS', data.data);
  },
};

const getters = {
  GET_FACTS: state => state.facts,
};

const mutations = {
  SET_FACTS: (state, payload) => {
    state.facts = payload;
  },
};

export default {
  state,
  actions,
  getters,
  mutations
};
