import moment from 'moment';
import axios from 'axios';

const getDefaultState = () => {
  return {
    events: null,
    selectedEvent: null
  };
};

const state = getDefaultState();

const actions = {
  GET_EVENTS_FROM_SERVER: async ({ commit }) => {
    const today = moment().format('YYYY-MM-DD');
    
    const { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/events?started_at=${today}&city_id=8&count=1000&sort=created_at&resolution=small`);
    commit('SET_EVENTS', data.data);
  },
  
  setSelectedEvent: ({ commit, dispatch, state }, data) => {
    commit("setSelectedEvent", data);
  }
};

const getters = {
  GET_EVENTS: state => state.events,
  
  selectedEvent: state => state.selectedEvent
};

const mutations = {
  SET_EVENTS: (state, payload) => {
    state.events = payload;
  },
  
  setSelectedEvent(state, res) {
    state.selectedEvent = res;
  }
};

export default {
  state,
  actions,
  getters,
  mutations
};
