const getDefaultState = () => {
  return {
    stories: []
  };
};

const state = getDefaultState();

const actions = {
  GET_STORIES_FROM_SERVER: ({ commit }) => {
    return api.getStories().then(res => {
      commit("SET_STORIES", res.data);
    });
  }
};

const getters = {
  GET_STORIES: state => state.stories,
};

const mutations = {
  SET_STORIES: (state, payload) => {
    state.stories = payload;
  },
};

export default {
  state,
  actions,
  getters,
  mutations
};
