//socket init
import store from "@/store/index.js";

let socket = new WebSocket("wss://smart-fe26.ru/transport/rest/ws/bus");

socket.onopen = function(e) {
  console.log("[open] Соединение установлено");
  console.log("Отправляем данные на сервер");
  socket.send({ action: 'getRoutes'} );
};

socket.onmessage = function(event) {
  const parsed = JSON.parse(event?.data);
  getCurrentBusesOnRoute(parsed);
};

socket.onerror = function(error) {
  console.log(`[error] ${error.message}`);
};

const getCurrentBusesOnRoute = function(data) {
  console.log(data, "socket data")
  store.commit("SET_BUS_ROUTES", data?.anims);
}

// update store
import Echo from 'laravel-echo';
window.Pusher = require('pusher-js');

export const echoInstance = new Echo({
  broadcaster: 'pusher',
  key: process.env.VUE_APP_PUSHER_APP_KEY,
  cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
  forceTLS: true
});


export { socket, getCurrentBusesOnRoute };


