<template>
  <div>
    <div class="routes-text">
      Маршруты через остановку
    </div>

    <div class="routes">
      <div
          v-for="item in stopProps"
          :key="item.route.id"
          class="route"
      >
        <img class="route__img" src="@/assets/img/busIcon.svg">
        <p class="route__name-route">
          {{ item.route.title }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RoutesThroughStation",

  props: {
    stopProps: {
      type: [Array, Object],
      default: () => [],
    },
  },
}
</script>

<style lang="scss" scoped>
.routes-text {
  font-size: 30px;
  font-weight: normal;
  line-height: 50px;
  letter-spacing: -0.5px;
  color: rgba(255, 255, 255, 0.7);

  padding: 0 32px 12px;
}

.routes {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  padding: 0 32px 32px;

  .route {
    display: flex;
    align-items: center;
    width: auto;
    padding: 16px;
    background: #3A3A3C;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1), 0px 1px 18px rgba(0, 0, 0, 0.08), 0px 6px 10px rgba(0, 0, 0, 0.05);
    border-radius: 14px;
    opacity: 0.9;

    margin-right: 32px;
    margin-bottom: 24px;

    &__img {
      padding: 12px;
      margin-right: 16px;
      background: #0B83FF;
      border-radius: 100px;
    }

    &__name-route {
      font-style: normal;
      font-weight: 500;
      font-size: 36px;
      line-height: 35px;
      letter-spacing: -0.5px;
      color: #fff;
    }
  }
}
</style>
