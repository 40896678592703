import axios from "axios";
import config from "./config";

var show_log = true;
var res_count = 1000;

// axios(config);
class API {
  // ----------------------
  // weather
  // ----------------------
  getWeather() {
    return axios
      .get(
        "https://api.openweathermap.org/data/2.5/weather?units=metric&lat=44.1319&lon=43.0305&appid=8a9b4c75a1373ba213555dd6788d99ba"
      )
      .then(res => {
        return res.data;
      });
  }

  // ----------------------
  // banners
  // --
  getBanners() {
    return this.get(`/panel-banners`);
  }

  // ----------------------
  // stories
  // ----------------------

  // ----------------------
  // city
  // ----------------------
  getCity() {
    return this.get('/cities')
  }

  // ----------------------
  // panel
  // ----------------------
  getPanel(id) {
    return this.get(`/panels/?panel_id=${id}`);
  }

  // ----------------------
  // transport
  // ----------------------
  getCurrentBusSchedule(busId) {
    return this.get(`https://smart-fe26.ru/transport/rest/bus/schedule?rid=${busId}`);
  }

  getStations() {
    return this.get('https://smart-fe26.ru/transport/rest/bus/env/station');
  }

  // ----------------------
  // stories
  // ----------------------
  getStories() {
    // TODO: temporary hardcoded ZHV city id
    return this.get(`/stories?city_id=8&count=${res_count}`);
  }

  // ----------------------
  // places
  // ----------------------
  getPlaces() {
    // TODO: temporary hardcoded ZHV city id
    return this.get("/places?city_id=8")
  }

  // ----------------------
  // objects
  // ----------------------
  getObjectTypes() {
    return this.get(`/types?count=${res_count}`);
  }
  getObjects(type, page, count = res_count, city) {
    const getCity = city ? `&city_id=${city}` : "";
    const getType = type && type != 0 ? `&type_id=${type}` : "";
    return this.get(`/places?page=${page}&count=${count}${getType}${getCity}&city_id=8`);
  }
  getObjectsByType(city, page, count = res_count) {
    const getCity = city ? `&city_id=${city}` : "";
    return this.get(`/types?page=${page}&places=${count}${getCity}`);
  }
  getNearObjects(id) {
    return this.get(`/places/?near=${id}&count=4`);
  }
  getObject(id) {
    return this.get(`/places/${id}`);
  }

  // ----------------------
  // events
  // ----------------------
  getEventCity(id) {
    return this.get(`/events?city_id=${id}`)
  }
  getAllEvents() {
    // TODO: temporary hardcoded ZHV city id
    return this.get('/events?city_id=8');
  }

  getEvents(dates, count = res_count, calend = 0, badge = 0) {
    count = count ? count : res_count;
    const started = dates && dates.length ? `&started_at=${dates[0]}` : "";
    const stoped = dates && dates.length == 2 ? `&stopped_at=${dates[1]}` : "";
    const calendar = calend ? `&calendar=${calend}` : "";
    const badge_id = badge ? `&badge_id=${badge}` : "";

    if (dates)
      return this.get(
        `/content/event/?${started}${stoped}&count=${count}${calendar}${badge_id}`
      );
    else
      return this.get(`/content/event/?count=${count}${calendar}${badge_id}`);
  }
  getEvent(id) {
    return this.get(`/content/event/${id}`);
  }
  // ----------------------
  // badges
  // ----------------------
  getBadges() {
    console.log(this, 'this');
    return this.get(`/badges`);
  }

  // ----------------------
  // facts
  // ----------------------
  getFacts(city, count = res_count, resolution='medium') {
    const getCity = city ? `&city_id=${city}` : "";
    return this.get(`/content/article/?resolution=${resolution}&count=${count}${getCity}`);
  }
  getFact(id) {
    return this.get(`/content/article/${id}`);
  }

  // ----------------------
  // general API interfaces
  // ----------------------

  // axios get interface
  get(url) {
    return new Promise((resolve, reject) => {
      if (show_log)
        console.log(
          "%c " + new Date().toISOString() + " | API request, GET: " + url,
          "background: #222; color: #bada55"
        );
      axios
        .get(`${url}`, config)
        .then(
          response => {
            resolve(response.data);
          },
          err => {
            reject(err);
          }
        )
        .catch(error => {
          reject(error);
        });
    });
  }

  // axios post interface
  post(url, data) {
    return new Promise((resolve, reject) => {
      if (show_log)
        console.log(
          "%c " + new Date().toISOString() + " | API request, POST: " + url,
          "background: #222; color: #bada55"
        );
      axios
        .post(url, data, config)
        .then(
          response => {
            resolve(response);
          },
          err => {
            reject(err);
          }
        )
        .catch(error => {
          reject(error);
        });
    });
  }

  // axios delete interface
  delete(url) {
    return new Promise((resolve, reject) => {
      if (show_log)
        console.log(
          "%c " + new Date().toISOString() + " | API request, DELETE: " + url,
          "background: #222; color: #bada55"
        );
      axios
        .delete(url, config)
        .then(
          response => {
            resolve(response.data);
          },
          err => {
            reject(err);
          }
        )
        .catch(error => {
          reject(error);
        });
    });
  }

  // search
  getSearchResults(query) {
    return this.get(`/search?query=${query}&city_id=8`);
  }

  getSearchFactsResults(query) {
    return this.get(`/search/facts?count=1&query=${query}&city_id=8`);
  }

  // autocopmleate search transport
  getTransportAutocompleate(value) {
    return this.get(`${process.env.VUE_APP_TRANSPORT_URL}/autocomplete/${value}`);
  }

  // bus station
  getBusStation() {
    let TEMPORY_CAP_REGION_ID = 12717;
    return this.get(`${config.baseTransportURL}/stops/${TEMPORY_CAP_REGION_ID}`);
  }

  // Geometry routes with indicating the direction
  getGeometryRoutes(routeId, stopId) {
    return this.get(`/track_dir/${routeId}/${stopId}`);
  }
}

export default API;
