const state = {
  banners: [],
};

const getters = {
  GET_BANNERS: state => state.banners,
};

const mutations = {
  SET_BANNERS: (state, data) => {
    state.banners = data.filter(item => !!item.image).map(item => item.image)
  }
};

const actions = {
  GET_BANNERS_FROM_SERVER: async (context) => {
    let {data} = await api.getBanners();
    context.commit('SET_BANNERS', data)
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
}
